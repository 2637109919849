.form-control{
    padding-left: 92%;
}
.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust this value for spacing between cards */
    justify-content: space-between;
  }
  
  .cardColumn {
    flex: 1;
  }

  .paginationContainer {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
    margin-top: 16px;
  }